@import "../global-values/constants.scss";
@import "../global-values/theme.scss";

@mixin style() {
    div[data-simplebar="init"] {
        max-height: 100svh;
        padding-bottom: 4rem;
    }

    .simplebar-track.simplebar-vertical {
        display: none;
    }

    .simplebar-mask{
        border-radius: 0 0 $rounding $rounding;
    } 
}