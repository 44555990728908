@use './components/nav-bar.scss';
@use './components/simplebar.scss';
@use './components/recipe.scss';
@use './components/modal.scss';
@use './components/lists.scss';
@use './components/ActionGroup.scss';
@use './components/ImageSelector.scss';
@use './components/interactable.scss';

@import './global-values/theme.scss';
@import './global-values/constants.scss';
@import './global-values/font.scss';

:root:has(.workbox-refresh-button) #theme {
    filter: brightness(0.2);
    pointer-events: none;
}

#root {
    height: 100svh;
}

.workbox-refresh-button {
    all: unset;

    position: absolute !important;

    top: 50% !important;
    left: 50% !important;
    bottom: unset !important;
    right: unset !important;

    transform: translate(-50%, -50%);

    z-index: 1111;

    width: 15rem;
    height: 5rem;
    padding: 1rem;
    background-color: var(--accent);
    color: var(--surface);

    border-radius: $rounding;
    box-shadow: $shadow;

    text-align: center;
    font-family: $font-family;
    font-size: 1.2rem;
    line-height: $body-line-height;
    letter-spacing: $body-letter-spacing;

    transition: $transition-speed;
}

#installApp {
    position: absolute;
    left: 50%;
    top: calc($padding * 1.5);

    transform: translateX(-50%) scale(100%);

    z-index: 100000;

    width: fit-content;
    height: 3rem;

    background-color: var(--accent);
    display: flex;
    flex-flow: row nowrap;

    transition: 1s ease;

    border-radius: 4rem;
    overflow: clip;

    box-shadow: $shadow;

    padding: 0 0 0 $padding;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    &.hidden {
        top: calc(-100%);
        transform: translateX(-50%) scale(1%);
        box-shadow: $shadow-active;
        opacity: 0.1;
    }

    div {
        height: 100%;

        &.pwa-popup-prompt {
            width: 100%;
            display: flex;

            p {
                margin: auto;
                font-size: 1.5rem;
                font-family: $font-family;
                line-height: $body-line-height;
                letter-spacing: $body-letter-spacing;
                color: var(--surface);
                display: block;
                height: fit-content;
                width: 16.5rem;
            }
        }

        &.pwa-popup-cancel {
            height: 100%;
            aspect-ratio: 1;
            display: flex;

            svg {
                margin: auto;
                width: 65%;
                stroke: var(--surface);
            }
        }
    }
}

#theme {
    overscroll-behavior: none;

    font-family: $font-family;
    font-size: $body;
    line-height: $body-line-height;
    letter-spacing: $body-letter-spacing;

    background-color: var(--color-background);
    color: var(--color-onBackground);

    width: 100vw;
    height: 100svh;

    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;

    user-select: none;
    overflow: clip;

    @include nav-bar.style();

    h1, h2 {
        color: var(--color-primary);
    }

    h1 {
        font-size: $title;
        font-weight: $bold;
        line-height: $title-line-height;
        letter-spacing: $title-letter-spacing;

        padding-bottom: $section-padding;
    }

    h2 {
        font-size: $subtitle;
        line-height: $subtitle-line-height;
        letter-spacing: $subtitle-letter-spacing;

        padding-bottom: $padding;
    }

    b, strong {
        font-weight: $bold;
    }

    .page-wrapper {
        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;

        overflow: clip;
    }

    .page {
        width: 100%;
        height: 100%;

        overflow: clip;
        
        @include simplebar.style();
        @include recipe.style();
        @include modal.style();
        @include lists.style();
        @include ActionGroup.style();
        @include ImageSelector.style();
        @include interactable.growingTextField();
        
        .success {
            background-color: var(--color-success);
            color: var(--color-onSuccess);
    
            h2, h3 {
                color: var(--color-onSuccess);
            }
        }
    
        .success-text {
            color: var(--color-success);
        }
    
        .warning {
            background-color: var(--color-warning);
            color: var(--color-onWarning);
    
            h2, h3 {
                color: var(--color-onWarning);
            }
        }
    
        .warning-text {
            color: var(--color-warning);
        }
    
        .danger {
            background-color: var(--color-danger);
            color: var(--color-onDanger);
    
            h2, h3 {
                color: var(--color-onDanger);
            }
        }
    
        .danger-text {
            color: var(--color-danger);
        }
    
        .extra-info {
            background-color: var(--color-info);
            color: var(--color-onInfo);
    
            h2, h3 {
                color: var(--color-onInfo);
            }
        }
    }
}