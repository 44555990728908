@use "./settings.scss";

@import "../global-values/constants.scss";
@import "../global-values/theme.scss";
@import "../global-values/font.scss";

@mixin style() {
    div.emptyPage {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: calc(50% - 2rem - 3.5rem);
        transform: translateX(-50%);

        padding: $padding;
        background-color: var(--color-info);
        color: var(--color-onInfo);

        border-radius: $rounding;

        height: fit-content;
        width: min(calc(80% - $padding * 2), 30rem);

        display: flex;
        box-shadow: $shadow;

        p {
            display: block;
            margin: auto;
            font-size: 1.2rem;
            width: fit-content;
            height: fit-content;
        }
    }

    div.list-of-cards-options {
        $size: 2.8rem;
        $max-width: 50rem;

        height: fit-content;
        max-width: calc($max-width - $padding * 2);
        
        display: flex;
        flex-flow: row wrap;

        @media only screen and (min-width: $max-width) {
            justify-content: center;
        }

        gap: $padding;

        margin: auto;
        padding: $padding;

        border-bottom: 2px outset rgba(0, 0, 0, 0.2);

        &.hidden {
            opacity: 0;
            pointer-events: none;
            user-select: none;
        }

        > div {
            height: $size;
            width: $size;
            border-radius: $size;
            background-color: var(--color-surface);
            color: var(--color-onSurface);
            font-size: 1.1rem;

            display: flex;

            transition: $transition-speed;

            box-shadow: $shadow;

            cursor: pointer;

            &:active {
                transform: scale(0.98);
                box-shadow: 0 0 5px $shadow-color;
            }

            svg {
                stroke: var(--color-onSurface);
                height: 60%;
                margin: auto;
                transition: $transition-speed;
            }

            &.active {
                background-color: var(--color-primary);
                color: var(--color-Surface);
                svg {
                    stroke: var(--color-surface);
                }
            }

            &.seperator {
                background-color: rgba(0, 0, 0, 0.2);
                box-shadow: none;
                pointer-events: none;
                border-radius: 0;
                width: 2px;
                height: calc($size * 0.8);
                margin: calc($size * 0.1) 0;
            }

            &.list-of-cards-options-text {
                width: fit-content;

                justify-content: center;
                align-items: center;

                padding: 0 $padding;

                gap: $sub-padding;

                &.delete {
                    background-color: var(--color-danger);

                    p {
                        color: var(--color-onDanger);
                    }

                    svg {
                        stroke: var(--color-onDanger);
                    }
                }

                &.search {
                    $border: 3px;
                    height: calc($size - 2 * $border);
                    border: solid $border var(--color-surface);
                    background-color: var(--color-surface);
                    display: grid;

                    grid-template-columns: 1fr $size;

                    padding: 0 0 0 $padding;

                    width: min(100%, calc(46rem));

                    input {
                        all: unset;
                        width: 100%;
                        cursor: text;
                    }

                    &:active, &:has(input:focus) {
                        border: solid $border var(--color-primary);
                        transform: unset;

                        svg {
                            stroke: var(--color-primary);
                        }
                    }
                }
    
                p {
                    display: block;
                    width: fit-content;
                    height: fit-content;
                    
                }
            }
        }
    }
    div.list-of-cards {
        $card-width: 23rem;

        margin: $padding auto 0 auto;

        width: calc(min(100%, 50rem) - $padding * 2);

        display: flex;
        flex-flow: row wrap;
        gap: calc($padding * 1.5);
        justify-content: center;

        transition: 0.3s ease-out;

        & > div {
            position: relative;

            border-radius: $rounding;
            overflow: clip;

            color: var(--color-onSurface);

            width: min($card-width, 100%);

            box-shadow: $shadow;

            background-color: var(--color-surface);
            transition: $transition-speed;

            &:active:not(.readonly):not(.card:has(.action-group:active)) {
                box-shadow: 0 0 0px $shadow-color;
                transform: scale(0.99);
            }

            h3 {
                padding-bottom: 0.5rem;
            }

            .card-image {
                aspect-ratio: 4 / 3;
                width: $card-width;
                    
                overflow: clip;

                position: relative;
                z-index: 0;

                img, div {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    overflow: hidden;
                }

                div {
                    background-color: var(--color-info);
                    display: flex;

                    svg {
                        margin: auto;
                        width: 3rem;
                        height: 3rem;

                        filter: drop-shadow($shadow);

                        stroke: var(--color-onInfo);
                    }
                }

                & + .card-content {
                    position: relative;
                    z-index: 1;
                    transform: translateY(-1.5rem);
                }
            }

            .card-content {
                padding: $padding $padding $padding $padding;
                border-radius: $rounding;
                
                background-color: var(--color-surface);

                h2 {
                    text-overflow: ellipsis;
                    overflow: hidden;

                    text-transform: capitalize;

                    &.short {
                        width: calc(100% - 5rem);
                    }
                }

                ul {
                    $height: 2.3rem;

                    li {
                        display: grid;
                        grid-template-columns: 1fr 5rem;
                        height: inherit;
                        width: inherit;

                        gap: 1rem;
                        padding: 0.4rem 0;

                        & + li {
                            border-top: solid rgba(0, 0, 0, 0.1) 1px;
                        }

                        div {
                            display: flex;
                            align-items: center;
                            p {
                                height: fit-content;
                                width: fit-content;
                                display: inline-block;
    
                                & + p {
                                    margin-left: 0.4rem;
                                }
                            }
                        }

                        span {
                            height: 100%;
                            width: 100%;

                            display: flex;
                            display: none;
                            svg {
                                stroke: var(--color-onSurface);
                                opacity: 0.6;
                                height: 1.5rem;

                                margin: auto;
                            }
                        }
                    }
                }

                #shopping-bag-recipe-quantity {
                    label {
                        display: none;
                    }

                    h3 {
                        display: inline-block;
                    }

                    input[type="number"] {
                        all: unset;
                        text-align: center;
                        height: fit-content;
                        width: fit-content;

                        margin: 0 0.5rem;

                        box-shadow: inset $shadow-active;
                        border-radius: $rounding-field;

                        appearance: none;
                        -moz-appearance: textfield;
                        display: inline-block;

                        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button  {
                            display: none;
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                }
            }

            &:has(.card-extra-info) .card-content {
                margin-bottom: calc($padding + 1rem);
            }

            .card-extra-info {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                gap: $padding;
                height: 1rem;
                margin: $padding;
                position: absolute;
                bottom: 0;

                .card-extra-info-segment {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;

                    opacity: 0.6;

                    svg {
                        stroke: var(--color-onSurface);
                        height: 1rem;
                        width: 1rem;

                        padding-left: $sub-padding;
                    }
                }
            }

            .card-extra-actions {
                position: absolute;
                z-index: 1;

                top: $padding;
                right: $padding;

                width: fit-content;
                height: fit-content;

                display: flex;
                gap: $sub-padding;

                background-color: $shadow-color;
                box-shadow: $shadow;
                border-radius: 2.8rem;
    
                padding: $sub-padding;

                .card-extra-action {
                    width: 2.8rem;
                    height: 2.8rem;
                    border-radius: 50%;
                    background-color: var(--color-surface);
                    display: flex;
                    
                    transition: 0.2s;

                    &:active {
                        transform: scale(0.98);
                        box-shadow: 0 0 5px $shadow-color;

                        &.card-extra-action-delete {
                            background-color: var(--color-danger);
                            svg {
                                stroke: var(--color-surface);
                            }
                        }
                    }

                    svg {
                        width: 60%;
                        margin: auto;
                        stroke: var(--color-onSurface);
                        transition: $transition-speed;
                    }
                }
            }
        }

        &.list-of-cards-single-row {
            & > div {
                width: 100%;
            }

            @include settings.style();
        }
    }
}