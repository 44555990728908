@import "../global-values/constants.scss";
@import "../global-values/theme.scss";

@mixin style() {
    div.nav-bar {
        $height: 3.5rem;
        $width: 100vw;
        $max-width: 70rem;
        $objects: 5;

        background-color: transparent;

        width: $width;
        max-width: $max-width;
        height: $height;

        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: space-between;

        margin: auto;

        z-index: 11;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        overflow: visible;

        &::after {
            content: '';
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: -1;
            background-image: var(--background-fade-bottom);
            width: 100%;
            height: 3.5rem;
        }

        svg, p {
            transition: $transition-speed;
        }

        a {
            -webkit-tap-highlight-color: transparent;
        }
        
        .nav-center {
            width: calc($height);
            height: calc($height * 1.2);

            border-radius: $height $height 0 0;

            box-shadow: 0 0 10px $shadow-color;

            position: absolute;
            bottom: 0;
            left: calc(min($width, $max-width) * 0.5);
            transform: translateX(-50%);
            z-index: 2;

            display: flex;
            justify-content: center;

            background-color: var(--color-primary);

            transition: $transition-speed;
            transition-property: height, box-shadow;

            svg {
                margin-top: 0.5rem;
                height: 2.5rem;

                path {
                    transition: $transition-speed;
                    stroke: var(--color-onSurface)
                }
            }

            &.pressing {
                height: calc($height * 1.3);
                box-shadow: 0 0 5px $shadow-color;
            }

            &.active {
                height: calc($height * 1.3);
                path {
                    stroke: var(--color-surface)
                }
            }
        }

        .nav-tabs {
            width: calc($width * 0.39);
            max-width: calc($max-width * 0.39);
            height: $height;
            display: flex;
            flex-flow: row nowrap;

            box-shadow: 0 0 10px $shadow-color;

            &.nav-tabs-left {
                border-radius: 0 $rounding 0 0;

                li:last-child a{
                    border-radius: 0 $rounding 0 0;
                }
            }

            &.nav-tabs-right {
                border-radius: $rounding 0 0 0;

                li:first-child a{
                    border-radius: $rounding 0 0 0;
                }
            }

            @media only screen and (min-width: $max-width) {
                &.nav-tabs-left {
                    border-radius: $rounding $rounding 0 0;
                    overflow: clip;

                    li:first-child a{
                        border-radius: $rounding 0 0 0;
                    }
                }
    
                &.nav-tabs-right {
                    border-radius: $rounding $rounding 0 0;
    
                    li:last-child a{
                        border-radius: 0 $rounding 0 0;
                    }
                }
            }

            .nav-tab {
                width: 50%;

                a {
                    font-size: 0.6rem;
                    text-align: center;
                    color: var(--color-onSurface);

                    text-decoration: none;

                    background-color: var(--color-surface);

                    height: 100%;
                    width: 100%;

                    display: flex;
                    flex-flow: column nowrap;

                    align-items: center;
                    justify-content: center;

                    box-shadow: inset 0px 0px 0px $shadow-color;
    
                    transition: $transition-speed;
    
                    &.pressing {
                        box-shadow: inset 0px 0px 10px $shadow-color;
                        svg, p {
                            transform: scale(90%);
                        }
                    }

                    &.active {
                        color: var(--color-primary);
                        svg path {
                            stroke: var(--color-primary)
                        }
                    }

                    svg {
                        height: 1.8rem;

                        path {
                            transition: $transition-speed;
                            stroke: var(--color-onSurface)
                        }
                    }   

                    p {
                        width: 90%;
                    }
                }
            }
        }
    }
}
