@use "./interactable.scss";

@import "../global-values/constants.scss";
@import "../global-values/theme.scss";
@import "../global-values/font.scss";

@mixin style() {
    $sub-border-width: 1px;
    $sub-padding: 0.5rem;
    $button-size: 3.5rem;

    #recipe-import {
        max-height: 20rem;
        overflow: clip;

        textarea {
            min-height: calc($body);
            border: solid 1px $shadow-color;
            border-radius: $rounding;
            padding: 0.5rem 0.5rem 0.9rem 0.5rem !important;
        }
    }

    #viewableRecipe, #updateableRecipe {
        position: absolute;

        width: 100%;
        height: calc(100% - 2rem - 3.5rem);
        z-index: 10;
        top: 0;
        left: 0;

        background-color: var(--color-background);
    }

    #recipe-preview-actions, #recipe-create-actions {
        position: absolute;
        top: $padding;
        right: $padding;

        z-index: 10;

        display: flex;
        flex-flow: column-reverse nowrap;
        gap: $padding;

        background-color: $shadow-color;
        box-shadow: $shadow;
        border-radius: $button-size;

        padding: $sub-padding;
        
        button, label {
            all: unset;
            width: calc($button-size * 0.8);
            height: calc($button-size * 0.8);
            border-radius: 50%;
            background-color: var(--color-surface);
            display: flex;

            box-shadow: $shadow;

            transition: $transition-speed;

            &:active {
                box-shadow: 0 0 5px $shadow-color;
                transform: scale(0.98);
            }

            svg {
                height: 60%;
                margin: auto;

                stroke: var(--color-onSurface);

                transition: $transition-speed;
            }
        }
    }

    $content-width: min(100%, 25rem);

    #main-info {
        $large-padding: calc($padding * 1.5);

        overflow-y: clip;

        z-index: 1;

        border-radius: $rounding;
        box-shadow: 0 0 10px $shadow-color;
        
        padding: $large-padding;

        background-color: var(--color-surface);
        color: var(--color-onSurface);

        transform: translateY(-$large-padding);

        width: calc(min(100%, 35rem) - $large-padding * 2);
        margin: auto;

        display: flex;
        flex-flow: column nowrap;

        > input, > textarea {
            font-size: 1.2rem;

            transition: border 0.2s;

            &:not(:last-child){
                margin-bottom: $padding;
            }
        }

        > textarea {
            padding: 0.5rem 0.5rem 0.9rem 0.5rem !important;
        }

        > input {
            all: unset;
            border-bottom: solid var(--color-onSurface) $sub-border-width;
            border-radius: $rounding-field;
            padding: 0 $rounding-field $sub-padding $rounding-field;

            width: calc($content-width - $rounding-field * 2);
            margin: auto;

            transition: $transition-speed;

            &#name {
                font-size: 1.5rem;
            }

            &:focus {
                border-bottom: solid var(--color-primary) $sub-border-width;
            }
        }

        > input:read-only {
            border: unset;
            padding: unset;
        }

        > textarea {
            width: $content-width;
            margin: auto;

            transition: border $transition-speed;
            &:not(.readonly) {
                border: solid var(--color-onSurface) $sub-border-width;
                border-radius: 5px;
                padding: $sub-padding;
                width: calc($content-width - ($sub-border-width * 2) - ($sub-padding * 2));

                &:focus {
                    border: solid var(--color-primary) $sub-border-width;
                }
            }
        }

        #extra-info-about-recipe{
            display: flex;
            flex-flow: row wrap;
            justify-content: center;

            gap: $padding;
            margin: $padding 0;

            div {
                display: flex;
                flex-flow: row-reverse wrap;
                align-items: center;
                justify-items: center;

                height: fit-content;
                width: fit-content;

                transition: 0.2s;

                border-bottom: solid var(--color-onSurface) 1px;
                border-radius: $rounding-field;
                padding: 0 $rounding-field $sub-padding $rounding-field;

                &:has(input:focus) {
                    svg {
                        stroke: var(--color-primary);
                    }
    
                    border-bottom: solid var(--color-primary) 1px;
                }

                &:has(input:read-only) {
                    border: unset;
                    padding: unset;
                }

                &#duration-field {
                    input {
                        width: calc(2.7rem - $rounding-field * 2);
                    }
                }

                &#quantity-field {
                    input {
                        width: calc(2.1rem - $rounding-field * 2);
                    }
                }

                label {
                    display: block;
                    height: 1.2rem;
                    width: 1.2rem;

                    svg {
                        width: 100%;
                        stroke: var(--color-onSurface);
                        transition: 0.2s;
                    }
                }

                input {
                    all: unset;
                    text-align: center;
                    height: fit-content;

                    appearance: none;
                    -moz-appearance: textfield;

                    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button  {
                        display: none;
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
            }
        }

        #recipe-actions {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            gap: $padding;

            margin-top: calc($padding * 2);

            button {
                all: unset;

                display: flex;
                flex-flow: row-reverse nowrap;
                align-items: center;
                justify-content: center;
                gap: $sub-padding;

                padding: 0 $padding;
                width: fit-content;
                height: 2.8rem;

                border-radius: 2.8rem;

                transition: $transition-speed;

                svg {
                    height: 60%;
                }

                p {
                    font-size: 1.1rem;
                    width: fit-content;
                    height: fit-content;
                    display: block;
                }

                &:active {
                    box-shadow: 0 0 5px $shadow-color;
                    transform: scale(0.98);
                }

                &.send {
                    background-color: var(--color-primary);
                    border: solid var(--color-primary) $sub-border-width;
                    color: var(--color-surface);

                    svg { 
                        stroke: var(--color-surface);
                    }
                }

                &.delete {
                    background-color: var(--color-danger);
                    color: var(--color-surface);

                    svg { 
                        stroke: var(--color-surface);
                    }
                }
            }
        }
    }

    .extendingList {
        width: $content-width;
        background-color: var(--color-primary);
        
        $header-height: 3.5rem;
        $content-index: 1;

        display: flex;
        flex-flow: column nowrap;
        border-radius: $rounding;
        
        overflow: clip;

        position: relative;

        box-shadow: $shadow-active;

        margin: auto;

        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            margin-top: $header-height;
            width: 100%;
            height: calc(100% - $header-height);
            background-color: var(--color-surface);
        }

        .extendingListHeader {
            height: $header-height;
            z-index: $content-index;

            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 0 1rem;

            &.active {
                display: flex;
            }
    
            &.inactive {
                display: none;
            }

            h2 {
                text-align: center;
                color: var(--color-surface);

                font-size: 1.7rem;

                padding: 0;
            }

            button {
                all: unset;

                $size: 2.3rem;
                height: $size;
                width: $size;

                transition: $transition-speed;

                display: flex;

                background-color: var(--color-surface);
                box-shadow: $shadow;

                border-radius: 50%;

                &.disabled {
                    pointer-events: none;
                    opacity: 0.3;
                    box-shadow: 0 0 0 $shadow-color;
                    background-color: inherit;
                }        

                svg {
                    height: 60%;
                    stroke: var(--color-onSurface);
                    margin: auto;
                }

                &:active {
                    box-shadow: 0 0 5px $shadow-color;
                    transform: scale(0.98);
                }
            }
        }

        .extendingListItem {
            $item-size: 2.3rem;

            width: 100%;

            z-index: $content-index;

            display: grid;
            align-items: center;
            gap: 0.4rem;

            background-color: transparent;
            color: var(--color-onSurface);

            background-image: linear-gradient(var(--color-onSurface) 1px, transparent 1px);
            background-size: 100%;
            position: relative;

            &.active {
                display: grid;
            }
    
            &.inactive {
                display: none;
            }
            
            &.ingredient {
                grid-template-columns: $item-size 1fr;

                &:has(.unit.visible):has(.amount.visible) {
                    grid-template-columns: $item-size 1fr 3rem 4rem;
                }

                .name{
                    $padding: 0.4rem;

                    min-height: calc($item-size - 2 * $padding);
                    padding: $padding 0;
                }
            }

            &.step {
                grid-template-columns: $item-size 1fr;

                .text{
                    $padding: 0.4rem;

                    min-height: calc($item-size - 2 * $padding);
                    padding: $padding 0;
                }
            }

            .laneIndicator {
                background-image: linear-gradient(to left, var(--color-primary) 2px, transparent 2px);
                background-size: 100%;

                display: flex;
                justify-content: center;
                align-items: center;

                height: 100%;
                width: inherit;

                padding-right: 1px;

                min-height: fit-content;

                span {
                    height: fit-content;
                    width: fit-content;

                    opacity: 0.7;
                }

                input[type="text"] {
                    all: unset;

                    height: 100%;
                    width: 100%;
                    
                    text-align: center;
                }

                .hidden{
                    display: none;
                }
            }

            div {
                height: $item-size;
                min-width: $item-size;
            }

            input[type="text"] {
                all: unset;

                height: $item-size;
                width: inherit;

                &.unit {
                    display: none;

                    &.visible{
                        display: block;
                    }
                }
                 
                &.amount {
                    text-align: end;

                    display: none;

                    &.visible {
                        display: block;
                    }
                }
            }
        }
    }
}