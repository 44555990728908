:root {
  --surface: #edeae3;
  --main-brand: #74978e;
  --accent: #4d565d;
  --background: #2a2c35;
  --success: #58a863;
  --warning: #d5982b;
  --danger: #f44336;
  background-color: var(--dark-shades);
}

html, body {
  background-color: var(--dark-shades);
}

#theme {
  --color-primary: var(--main-brand);
  --color-success: var(--success);
  --color-onSuccess: var(--surface);
  --color-warning: var(--warning);
  --color-onWarning: var(--surface);
  --color-danger: var(--danger);
  --color-onDanger: var(--surface);
  --color-info: var(--accent);
  --color-onInfo: var(--surface);
  --color-background: var(--background);
  --color-onBackground: var(--surface);
  --color-surface: var(--surface);
  --color-onSurface: var(--background);
}

:root {
  --surface: #edeae3;
  --main-brand: #74978e;
  --accent: #4d565d;
  --background: #2a2c35;
  --success: #58a863;
  --warning: #d5982b;
  --danger: #f44336;
  background-color: var(--dark-shades);
}

html, body {
  background-color: var(--dark-shades);
}

#theme {
  --color-primary: var(--main-brand);
  --color-success: var(--success);
  --color-onSuccess: var(--surface);
  --color-warning: var(--warning);
  --color-onWarning: var(--surface);
  --color-danger: var(--danger);
  --color-onDanger: var(--surface);
  --color-info: var(--accent);
  --color-onInfo: var(--surface);
  --color-background: var(--background);
  --color-onBackground: var(--surface);
  --color-surface: var(--surface);
  --color-onSurface: var(--background);
}

:root {
  --surface: #edeae3;
  --main-brand: #74978e;
  --accent: #4d565d;
  --background: #2a2c35;
  --success: #58a863;
  --warning: #d5982b;
  --danger: #f44336;
  background-color: var(--dark-shades);
}

html, body {
  background-color: var(--dark-shades);
}

#theme {
  --color-primary: var(--main-brand);
  --color-success: var(--success);
  --color-onSuccess: var(--surface);
  --color-warning: var(--warning);
  --color-onWarning: var(--surface);
  --color-danger: var(--danger);
  --color-onDanger: var(--surface);
  --color-info: var(--accent);
  --color-onInfo: var(--surface);
  --color-background: var(--background);
  --color-onBackground: var(--surface);
  --color-surface: var(--surface);
  --color-onSurface: var(--background);
}

:root {
  --surface: #edeae3;
  --main-brand: #74978e;
  --accent: #4d565d;
  --background: #2a2c35;
  --success: #58a863;
  --warning: #d5982b;
  --danger: #f44336;
  background-color: var(--dark-shades);
}

html, body {
  background-color: var(--dark-shades);
}

#theme {
  --color-primary: var(--main-brand);
  --color-success: var(--success);
  --color-onSuccess: var(--surface);
  --color-warning: var(--warning);
  --color-onWarning: var(--surface);
  --color-danger: var(--danger);
  --color-onDanger: var(--surface);
  --color-info: var(--accent);
  --color-onInfo: var(--surface);
  --color-background: var(--background);
  --color-onBackground: var(--surface);
  --color-surface: var(--surface);
  --color-onSurface: var(--background);
}

:root {
  --surface: #edeae3;
  --main-brand: #74978e;
  --accent: #4d565d;
  --background: #2a2c35;
  --success: #58a863;
  --warning: #d5982b;
  --danger: #f44336;
  background-color: var(--dark-shades);
}

html, body {
  background-color: var(--dark-shades);
}

#theme {
  --color-primary: var(--main-brand);
  --color-success: var(--success);
  --color-onSuccess: var(--surface);
  --color-warning: var(--warning);
  --color-onWarning: var(--surface);
  --color-danger: var(--danger);
  --color-onDanger: var(--surface);
  --color-info: var(--accent);
  --color-onInfo: var(--surface);
  --color-background: var(--background);
  --color-onBackground: var(--surface);
  --color-surface: var(--surface);
  --color-onSurface: var(--background);
}

:root {
  --surface: #edeae3;
  --main-brand: #74978e;
  --accent: #4d565d;
  --background: #2a2c35;
  --success: #58a863;
  --warning: #d5982b;
  --danger: #f44336;
  background-color: var(--dark-shades);
}

html, body {
  background-color: var(--dark-shades);
}

#theme {
  --color-primary: var(--main-brand);
  --color-success: var(--success);
  --color-onSuccess: var(--surface);
  --color-warning: var(--warning);
  --color-onWarning: var(--surface);
  --color-danger: var(--danger);
  --color-onDanger: var(--surface);
  --color-info: var(--accent);
  --color-onInfo: var(--surface);
  --color-background: var(--background);
  --color-onBackground: var(--surface);
  --color-surface: var(--surface);
  --color-onSurface: var(--background);
}

:root {
  --surface: #edeae3;
  --main-brand: #74978e;
  --accent: #4d565d;
  --background: #2a2c35;
  --success: #58a863;
  --warning: #d5982b;
  --danger: #f44336;
  background-color: var(--dark-shades);
}

html, body {
  background-color: var(--dark-shades);
}

#theme {
  --color-primary: var(--main-brand);
  --color-success: var(--success);
  --color-onSuccess: var(--surface);
  --color-warning: var(--warning);
  --color-onWarning: var(--surface);
  --color-danger: var(--danger);
  --color-onDanger: var(--surface);
  --color-info: var(--accent);
  --color-onInfo: var(--surface);
  --color-background: var(--background);
  --color-onBackground: var(--surface);
  --color-surface: var(--surface);
  --color-onSurface: var(--background);
}

:root {
  --surface: #edeae3;
  --main-brand: #74978e;
  --accent: #4d565d;
  --background: #2a2c35;
  --success: #58a863;
  --warning: #d5982b;
  --danger: #f44336;
  background-color: var(--dark-shades);
}

html, body {
  background-color: var(--dark-shades);
}

#theme {
  --color-primary: var(--main-brand);
  --color-success: var(--success);
  --color-onSuccess: var(--surface);
  --color-warning: var(--warning);
  --color-onWarning: var(--surface);
  --color-danger: var(--danger);
  --color-onDanger: var(--surface);
  --color-info: var(--accent);
  --color-onInfo: var(--surface);
  --color-background: var(--background);
  --color-onBackground: var(--surface);
  --color-surface: var(--surface);
  --color-onSurface: var(--background);
}

:root {
  --surface: #edeae3;
  --main-brand: #74978e;
  --accent: #4d565d;
  --background: #2a2c35;
  --success: #58a863;
  --warning: #d5982b;
  --danger: #f44336;
  background-color: var(--dark-shades);
}

html, body {
  background-color: var(--dark-shades);
}

#theme {
  --color-primary: var(--main-brand);
  --color-success: var(--success);
  --color-onSuccess: var(--surface);
  --color-warning: var(--warning);
  --color-onWarning: var(--surface);
  --color-danger: var(--danger);
  --color-onDanger: var(--surface);
  --color-info: var(--accent);
  --color-onInfo: var(--surface);
  --color-background: var(--background);
  --color-onBackground: var(--surface);
  --color-surface: var(--surface);
  --color-onSurface: var(--background);
}

:root {
  --surface: #edeae3;
  --main-brand: #74978e;
  --accent: #4d565d;
  --background: #2a2c35;
  --success: #58a863;
  --warning: #d5982b;
  --danger: #f44336;
  background-color: var(--dark-shades);
}

html, body {
  background-color: var(--dark-shades);
}

#theme {
  --color-primary: var(--main-brand);
  --color-success: var(--success);
  --color-onSuccess: var(--surface);
  --color-warning: var(--warning);
  --color-onWarning: var(--surface);
  --color-danger: var(--danger);
  --color-onDanger: var(--surface);
  --color-info: var(--accent);
  --color-onInfo: var(--surface);
  --color-background: var(--background);
  --color-onBackground: var(--surface);
  --color-surface: var(--surface);
  --color-onSurface: var(--background);
}

:root:has(.workbox-refresh-button) #theme {
  filter: brightness(0.2);
  pointer-events: none;
}

#root {
  height: 100svh;
}

.workbox-refresh-button {
  all: unset;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  bottom: unset !important;
  right: unset !important;
  transform: translate(-50%, -50%);
  z-index: 1111;
  width: 15rem;
  height: 5rem;
  padding: 1rem;
  background-color: var(--accent);
  color: var(--surface);
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: "Work Sans", sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: normal;
  transition: 0.2s;
}

#installApp {
  position: absolute;
  left: 50%;
  top: 1.5rem;
  transform: translateX(-50%) scale(100%);
  z-index: 100000;
  width: fit-content;
  height: 3rem;
  background-color: var(--accent);
  display: flex;
  flex-flow: row nowrap;
  transition: 1s ease;
  border-radius: 4rem;
  overflow: clip;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0 0 0 1rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
#installApp.hidden {
  top: -100%;
  transform: translateX(-50%) scale(1%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  opacity: 0.1;
}
#installApp div {
  height: 100%;
}
#installApp div.pwa-popup-prompt {
  width: 100%;
  display: flex;
}
#installApp div.pwa-popup-prompt p {
  margin: auto;
  font-size: 1.5rem;
  font-family: "Work Sans", sans-serif;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--surface);
  display: block;
  height: fit-content;
  width: 16.5rem;
}
#installApp div.pwa-popup-cancel {
  height: 100%;
  aspect-ratio: 1;
  display: flex;
}
#installApp div.pwa-popup-cancel svg {
  margin: auto;
  width: 65%;
  stroke: var(--surface);
}

#theme {
  overscroll-behavior: none;
  font-family: "Work Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: normal;
  background-color: var(--color-background);
  color: var(--color-onBackground);
  width: 100vw;
  height: 100svh;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  overflow: clip;
}
#theme div.nav-bar {
  background-color: transparent;
  width: 100vw;
  max-width: 70rem;
  height: 3.5rem;
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  z-index: 11;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: visible;
}
#theme div.nav-bar::after {
  content: "";
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-image: var(--background-fade-bottom);
  width: 100%;
  height: 3.5rem;
}
#theme div.nav-bar svg, #theme div.nav-bar p {
  transition: 0.2s;
}
#theme div.nav-bar a {
  -webkit-tap-highlight-color: transparent;
}
#theme div.nav-bar .nav-center {
  width: 3.5rem;
  height: 4.2rem;
  border-radius: 3.5rem 3.5rem 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: calc(min(100vw, 70rem) * 0.5);
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  justify-content: center;
  background-color: var(--color-primary);
  transition: 0.2s;
  transition-property: height, box-shadow;
}
#theme div.nav-bar .nav-center svg {
  margin-top: 0.5rem;
  height: 2.5rem;
}
#theme div.nav-bar .nav-center svg path {
  transition: 0.2s;
  stroke: var(--color-onSurface);
}
#theme div.nav-bar .nav-center.pressing {
  height: 4.55rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
#theme div.nav-bar .nav-center.active {
  height: 4.55rem;
}
#theme div.nav-bar .nav-center.active path {
  stroke: var(--color-surface);
}
#theme div.nav-bar .nav-tabs {
  width: 39vw;
  max-width: 27.3rem;
  height: 3.5rem;
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
#theme div.nav-bar .nav-tabs.nav-tabs-left {
  border-radius: 0 1rem 0 0;
}
#theme div.nav-bar .nav-tabs.nav-tabs-left li:last-child a {
  border-radius: 0 1rem 0 0;
}
#theme div.nav-bar .nav-tabs.nav-tabs-right {
  border-radius: 1rem 0 0 0;
}
#theme div.nav-bar .nav-tabs.nav-tabs-right li:first-child a {
  border-radius: 1rem 0 0 0;
}
@media only screen and (min-width: 70rem) {
  #theme div.nav-bar .nav-tabs.nav-tabs-left {
    border-radius: 1rem 1rem 0 0;
    overflow: clip;
  }
  #theme div.nav-bar .nav-tabs.nav-tabs-left li:first-child a {
    border-radius: 1rem 0 0 0;
  }
  #theme div.nav-bar .nav-tabs.nav-tabs-right {
    border-radius: 1rem 1rem 0 0;
  }
  #theme div.nav-bar .nav-tabs.nav-tabs-right li:last-child a {
    border-radius: 0 1rem 0 0;
  }
}
#theme div.nav-bar .nav-tabs .nav-tab {
  width: 50%;
}
#theme div.nav-bar .nav-tabs .nav-tab a {
  font-size: 0.6rem;
  text-align: center;
  color: var(--color-onSurface);
  text-decoration: none;
  background-color: var(--color-surface);
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1);
  transition: 0.2s;
}
#theme div.nav-bar .nav-tabs .nav-tab a.pressing {
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
}
#theme div.nav-bar .nav-tabs .nav-tab a.pressing svg, #theme div.nav-bar .nav-tabs .nav-tab a.pressing p {
  transform: scale(90%);
}
#theme div.nav-bar .nav-tabs .nav-tab a.active {
  color: var(--color-primary);
}
#theme div.nav-bar .nav-tabs .nav-tab a.active svg path {
  stroke: var(--color-primary);
}
#theme div.nav-bar .nav-tabs .nav-tab a svg {
  height: 1.8rem;
}
#theme div.nav-bar .nav-tabs .nav-tab a svg path {
  transition: 0.2s;
  stroke: var(--color-onSurface);
}
#theme div.nav-bar .nav-tabs .nav-tab a p {
  width: 90%;
}
#theme h1, #theme h2 {
  color: var(--color-primary);
}
#theme h1 {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -0.048rem;
  padding-bottom: 2rem;
}
#theme h2 {
  font-size: 1.8rem;
  line-height: 1.4;
  letter-spacing: -0.036rem;
  padding-bottom: 1rem;
}
#theme b, #theme strong {
  font-weight: bold;
}
#theme .page-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: clip;
}
#theme .page {
  width: 100%;
  height: 100%;
  overflow: clip;
}
#theme .page div[data-simplebar=init] {
  max-height: 100svh;
  padding-bottom: 4rem;
}
#theme .page .simplebar-track.simplebar-vertical {
  display: none;
}
#theme .page .simplebar-mask {
  border-radius: 0 0 1rem 1rem;
}
#theme .page #recipe-import {
  max-height: 20rem;
  overflow: clip;
}
#theme .page #recipe-import textarea {
  min-height: 1rem;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 0.5rem 0.5rem 0.9rem 0.5rem !important;
}
#theme .page #viewableRecipe, #theme .page #updateableRecipe {
  position: absolute;
  width: 100%;
  height: calc(100% - 2rem - 3.5rem);
  z-index: 10;
  top: 0;
  left: 0;
  background-color: var(--color-background);
}
#theme .page #recipe-preview-actions, #theme .page #recipe-create-actions {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
  display: flex;
  flex-flow: column-reverse nowrap;
  gap: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 3.5rem;
  padding: 0.5rem;
}
#theme .page #recipe-preview-actions button, #theme .page #recipe-preview-actions label, #theme .page #recipe-create-actions button, #theme .page #recipe-create-actions label {
  all: unset;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  background-color: var(--color-surface);
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 0.2s;
}
#theme .page #recipe-preview-actions button:active, #theme .page #recipe-preview-actions label:active, #theme .page #recipe-create-actions button:active, #theme .page #recipe-create-actions label:active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transform: scale(0.98);
}
#theme .page #recipe-preview-actions button svg, #theme .page #recipe-preview-actions label svg, #theme .page #recipe-create-actions button svg, #theme .page #recipe-create-actions label svg {
  height: 60%;
  margin: auto;
  stroke: var(--color-onSurface);
  transition: 0.2s;
}
#theme .page #main-info {
  overflow-y: clip;
  z-index: 1;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  background-color: var(--color-surface);
  color: var(--color-onSurface);
  transform: translateY(-1.5rem);
  width: calc(min(100%, 35rem) - 3rem);
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
}
#theme .page #main-info > input, #theme .page #main-info > textarea {
  font-size: 1.2rem;
  transition: border 0.2s;
}
#theme .page #main-info > input:not(:last-child), #theme .page #main-info > textarea:not(:last-child) {
  margin-bottom: 1rem;
}
#theme .page #main-info > textarea {
  padding: 0.5rem 0.5rem 0.9rem 0.5rem !important;
}
#theme .page #main-info > input {
  all: unset;
  border-bottom: solid var(--color-onSurface) 1px;
  border-radius: 5px;
  padding: 0 5px 0.5rem 5px;
  width: calc(min(100%, 25rem) - 10px);
  margin: auto;
  transition: 0.2s;
}
#theme .page #main-info > input#name {
  font-size: 1.5rem;
}
#theme .page #main-info > input:focus {
  border-bottom: solid var(--color-primary) 1px;
}
#theme .page #main-info > input:read-only {
  border: unset;
  padding: unset;
}
#theme .page #main-info > textarea {
  width: min(100%, 25rem);
  margin: auto;
  transition: border 0.2s;
}
#theme .page #main-info > textarea:not(.readonly) {
  border: solid var(--color-onSurface) 1px;
  border-radius: 5px;
  padding: 0.5rem;
  width: calc(min(100%, 25rem) - 2px - 1rem);
}
#theme .page #main-info > textarea:not(.readonly):focus {
  border: solid var(--color-primary) 1px;
}
#theme .page #main-info #extra-info-about-recipe {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
}
#theme .page #main-info #extra-info-about-recipe div {
  display: flex;
  flex-flow: row-reverse wrap;
  align-items: center;
  justify-items: center;
  height: fit-content;
  width: fit-content;
  transition: 0.2s;
  border-bottom: solid var(--color-onSurface) 1px;
  border-radius: 5px;
  padding: 0 5px 0.5rem 5px;
}
#theme .page #main-info #extra-info-about-recipe div:has(input:focus) {
  border-bottom: solid var(--color-primary) 1px;
}
#theme .page #main-info #extra-info-about-recipe div:has(input:focus) svg {
  stroke: var(--color-primary);
}
#theme .page #main-info #extra-info-about-recipe div:has(input:read-only) {
  border: unset;
  padding: unset;
}
#theme .page #main-info #extra-info-about-recipe div#duration-field input {
  width: calc(2.7rem - 10px);
}
#theme .page #main-info #extra-info-about-recipe div#quantity-field input {
  width: calc(2.1rem - 10px);
}
#theme .page #main-info #extra-info-about-recipe div label {
  display: block;
  height: 1.2rem;
  width: 1.2rem;
}
#theme .page #main-info #extra-info-about-recipe div label svg {
  width: 100%;
  stroke: var(--color-onSurface);
  transition: 0.2s;
}
#theme .page #main-info #extra-info-about-recipe div input {
  all: unset;
  text-align: center;
  height: fit-content;
  appearance: none;
  -moz-appearance: textfield;
}
#theme .page #main-info #extra-info-about-recipe div input::-webkit-inner-spin-button, #theme .page #main-info #extra-info-about-recipe div input::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
#theme .page #main-info #recipe-actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}
#theme .page #main-info #recipe-actions button {
  all: unset;
  display: flex;
  flex-flow: row-reverse nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 1rem;
  width: fit-content;
  height: 2.8rem;
  border-radius: 2.8rem;
  transition: 0.2s;
}
#theme .page #main-info #recipe-actions button svg {
  height: 60%;
}
#theme .page #main-info #recipe-actions button p {
  font-size: 1.1rem;
  width: fit-content;
  height: fit-content;
  display: block;
}
#theme .page #main-info #recipe-actions button:active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transform: scale(0.98);
}
#theme .page #main-info #recipe-actions button.send {
  background-color: var(--color-primary);
  border: solid var(--color-primary) 1px;
  color: var(--color-surface);
}
#theme .page #main-info #recipe-actions button.send svg {
  stroke: var(--color-surface);
}
#theme .page #main-info #recipe-actions button.delete {
  background-color: var(--color-danger);
  color: var(--color-surface);
}
#theme .page #main-info #recipe-actions button.delete svg {
  stroke: var(--color-surface);
}
#theme .page .extendingList {
  width: min(100%, 25rem);
  background-color: var(--color-primary);
  display: flex;
  flex-flow: column nowrap;
  border-radius: 1rem;
  overflow: clip;
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: auto;
}
#theme .page .extendingList::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 3.5rem;
  width: 100%;
  height: calc(100% - 3.5rem);
  background-color: var(--color-surface);
}
#theme .page .extendingList .extendingListHeader {
  height: 3.5rem;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}
#theme .page .extendingList .extendingListHeader.active {
  display: flex;
}
#theme .page .extendingList .extendingListHeader.inactive {
  display: none;
}
#theme .page .extendingList .extendingListHeader h2 {
  text-align: center;
  color: var(--color-surface);
  font-size: 1.7rem;
  padding: 0;
}
#theme .page .extendingList .extendingListHeader button {
  all: unset;
  height: 2.3rem;
  width: 2.3rem;
  transition: 0.2s;
  display: flex;
  background-color: var(--color-surface);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
#theme .page .extendingList .extendingListHeader button.disabled {
  pointer-events: none;
  opacity: 0.3;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  background-color: inherit;
}
#theme .page .extendingList .extendingListHeader button svg {
  height: 60%;
  stroke: var(--color-onSurface);
  margin: auto;
}
#theme .page .extendingList .extendingListHeader button:active {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transform: scale(0.98);
}
#theme .page .extendingList .extendingListItem {
  width: 100%;
  z-index: 1;
  display: grid;
  align-items: center;
  gap: 0.4rem;
  background-color: transparent;
  color: var(--color-onSurface);
  background-image: linear-gradient(var(--color-onSurface) 1px, transparent 1px);
  background-size: 100%;
  position: relative;
}
#theme .page .extendingList .extendingListItem.active {
  display: grid;
}
#theme .page .extendingList .extendingListItem.inactive {
  display: none;
}
#theme .page .extendingList .extendingListItem.ingredient {
  grid-template-columns: 2.3rem 1fr;
}
#theme .page .extendingList .extendingListItem.ingredient:has(.unit.visible):has(.amount.visible) {
  grid-template-columns: 2.3rem 1fr 3rem 4rem;
}
#theme .page .extendingList .extendingListItem.ingredient .name {
  min-height: 1.5rem;
  padding: 0.4rem 0;
}
#theme .page .extendingList .extendingListItem.step {
  grid-template-columns: 2.3rem 1fr;
}
#theme .page .extendingList .extendingListItem.step .text {
  min-height: 1.5rem;
  padding: 0.4rem 0;
}
#theme .page .extendingList .extendingListItem .laneIndicator {
  background-image: linear-gradient(to left, var(--color-primary) 2px, transparent 2px);
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: inherit;
  padding-right: 1px;
  min-height: fit-content;
}
#theme .page .extendingList .extendingListItem .laneIndicator span {
  height: fit-content;
  width: fit-content;
  opacity: 0.7;
}
#theme .page .extendingList .extendingListItem .laneIndicator input[type=text] {
  all: unset;
  height: 100%;
  width: 100%;
  text-align: center;
}
#theme .page .extendingList .extendingListItem .laneIndicator .hidden {
  display: none;
}
#theme .page .extendingList .extendingListItem div {
  height: 2.3rem;
  min-width: 2.3rem;
}
#theme .page .extendingList .extendingListItem input[type=text] {
  all: unset;
  height: 2.3rem;
  width: inherit;
}
#theme .page .extendingList .extendingListItem input[type=text].unit {
  display: none;
}
#theme .page .extendingList .extendingListItem input[type=text].unit.visible {
  display: block;
}
#theme .page .extendingList .extendingListItem input[type=text].amount {
  text-align: end;
  display: none;
}
#theme .page .extendingList .extendingListItem input[type=text].amount.visible {
  display: block;
}
#theme .page .modal-backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  display: none;
  backdrop-filter: blur(3px);
}
#theme .page .modal-backdrop.modal-opening {
  animation-name: fadeIn;
}
#theme .page .modal-backdrop.modal-opening .modal {
  animation-name: flyIn;
}
#theme .page .modal-backdrop.modal-closing {
  animation-name: fadeOut;
}
#theme .page .modal-backdrop.modal-closing.continue .modal {
  animation-name: flyOut-Continue;
}
#theme .page .modal-backdrop.modal-closing.cancel .modal {
  animation-name: flyOut-Cancel;
}
#theme .page .modal-backdrop.modal-closed {
  opacity: 0;
  backdrop-filter: blur(0);
}
#theme .page .modal-backdrop.modal-closed .modal {
  transform: translateY(-50%);
}
#theme .page .modal-backdrop.modal-open {
  pointer-events: all;
}
#theme .page .modal-backdrop:not(.modal-closed) {
  display: flex;
}
@keyframes flyIn {
  from {
    transform: translateY(-10%);
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.1);
  }
  to {
    transform: translateY(0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    backdrop-filter: blur(0);
  }
  to {
    opacity: 1;
    backdrop-filter: blur(3px);
  }
}
@keyframes flyOut-Continue {
  from {
    transform: translateX(0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  to {
    transform: translateX(-20%);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  }
}
@keyframes flyOut-Cancel {
  from {
    transform: translateX(0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  to {
    transform: translateX(20%);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
    backdrop-filter: blur(3px);
  }
  to {
    opacity: 0;
    backdrop-filter: blur(0);
  }
}
#theme .page .modal-backdrop .modal {
  background-color: var(--color-info);
  color: var(--color-onInfo);
  border-radius: 1rem;
  height: fit-content;
  width: calc(min(80%, 30rem) - 2rem);
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}
#theme .page .modal-backdrop .modal p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
#theme .page .modal-backdrop .modal .modal-actions {
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
}
#theme .page .modal-backdrop .modal .modal-actions button {
  all: unset;
  padding: 0 0.5rem;
  border-radius: 2.8rem;
  width: 5rem;
  height: 2.8rem;
}
#theme .page .modal-backdrop .modal .modal-actions button:active {
  transform: scale(0.98);
}
#theme .page .modal-backdrop .modal .modal-actions button.modal-continue {
  background-color: var(--color-primary);
  color: var(--color-surface);
}
#theme .page .modal-backdrop .modal .modal-actions button.modal-cancel {
  background-color: var(--color-danger);
  color: var(--color-onDanger);
}
#theme .page .modal-backdrop .modal .modal-container {
  padding: 1rem;
  background-color: var(--color-surface);
  margin-bottom: 1rem;
  border-radius: 1rem;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}
#theme .page .modal-backdrop .modal .modal-container #shopping-bag-modal-quantity {
  display: flex;
  flex-flow: row-reverse wrap;
  align-items: center;
  justify-items: center;
  height: fit-content;
  width: 5rem;
  transition: 0.2s;
  color: var(--color-onSurface);
  border-bottom: solid var(--color-onSurface) 1px;
  border-radius: 5px;
  padding: 0 5px 0.5rem 5px;
}
#theme .page .modal-backdrop .modal .modal-container #shopping-bag-modal-quantity:has(input:focus) {
  border-bottom: solid var(--color-primary) 1px;
}
#theme .page .modal-backdrop .modal .modal-container #shopping-bag-modal-quantity:has(input:focus) svg {
  stroke: var(--color-primary);
}
#theme .page .modal-backdrop .modal .modal-container #shopping-bag-modal-quantity label {
  display: block;
  height: 1.2rem;
  width: 1.2rem;
}
#theme .page .modal-backdrop .modal .modal-container #shopping-bag-modal-quantity label svg {
  width: 100%;
  stroke: var(--color-onSurface);
  transition: 0.2s;
}
#theme .page .modal-backdrop .modal .modal-container #shopping-bag-modal-quantity input[type=number] {
  all: unset;
  text-align: center;
  height: fit-content;
  width: calc(100% - 1.2rem);
  appearance: none;
  -moz-appearance: textfield;
}
#theme .page .modal-backdrop .modal .modal-container #shopping-bag-modal-quantity input[type=number]::-webkit-inner-spin-button, #theme .page .modal-backdrop .modal .modal-container #shopping-bag-modal-quantity input[type=number]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
#theme .page div.emptyPage {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: calc(50% - 2rem - 3.5rem);
  transform: translateX(-50%);
  padding: 1rem;
  background-color: var(--color-info);
  color: var(--color-onInfo);
  border-radius: 1rem;
  height: fit-content;
  width: min(80% - 2rem, 30rem);
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
#theme .page div.emptyPage p {
  display: block;
  margin: auto;
  font-size: 1.2rem;
  width: fit-content;
  height: fit-content;
}
#theme .page div.list-of-cards-options {
  height: fit-content;
  max-width: 48rem;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  margin: auto;
  padding: 1rem;
  border-bottom: 2px outset rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width: 50rem) {
  #theme .page div.list-of-cards-options {
    justify-content: center;
  }
}
#theme .page div.list-of-cards-options.hidden {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}
#theme .page div.list-of-cards-options > div {
  height: 2.8rem;
  width: 2.8rem;
  border-radius: 2.8rem;
  background-color: var(--color-surface);
  color: var(--color-onSurface);
  font-size: 1.1rem;
  display: flex;
  transition: 0.2s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
#theme .page div.list-of-cards-options > div:active {
  transform: scale(0.98);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
#theme .page div.list-of-cards-options > div svg {
  stroke: var(--color-onSurface);
  height: 60%;
  margin: auto;
  transition: 0.2s;
}
#theme .page div.list-of-cards-options > div.active {
  background-color: var(--color-primary);
  color: var(--color-Surface);
}
#theme .page div.list-of-cards-options > div.active svg {
  stroke: var(--color-surface);
}
#theme .page div.list-of-cards-options > div.seperator {
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: none;
  pointer-events: none;
  border-radius: 0;
  width: 2px;
  height: 2.24rem;
  margin: 0.28rem 0;
}
#theme .page div.list-of-cards-options > div.list-of-cards-options-text {
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  gap: 0.5rem;
}
#theme .page div.list-of-cards-options > div.list-of-cards-options-text.delete {
  background-color: var(--color-danger);
}
#theme .page div.list-of-cards-options > div.list-of-cards-options-text.delete p {
  color: var(--color-onDanger);
}
#theme .page div.list-of-cards-options > div.list-of-cards-options-text.delete svg {
  stroke: var(--color-onDanger);
}
#theme .page div.list-of-cards-options > div.list-of-cards-options-text.search {
  height: calc(2.8rem - 6px);
  border: solid 3px var(--color-surface);
  background-color: var(--color-surface);
  display: grid;
  grid-template-columns: 1fr 2.8rem;
  padding: 0 0 0 1rem;
  width: min(100%, 46rem);
}
#theme .page div.list-of-cards-options > div.list-of-cards-options-text.search input {
  all: unset;
  width: 100%;
  cursor: text;
}
#theme .page div.list-of-cards-options > div.list-of-cards-options-text.search:active, #theme .page div.list-of-cards-options > div.list-of-cards-options-text.search:has(input:focus) {
  border: solid 3px var(--color-primary);
  transform: unset;
}
#theme .page div.list-of-cards-options > div.list-of-cards-options-text.search:active svg, #theme .page div.list-of-cards-options > div.list-of-cards-options-text.search:has(input:focus) svg {
  stroke: var(--color-primary);
}
#theme .page div.list-of-cards-options > div.list-of-cards-options-text p {
  display: block;
  width: fit-content;
  height: fit-content;
}
#theme .page div.list-of-cards {
  margin: 1rem auto 0 auto;
  width: calc(min(100%, 50rem) - 2rem);
  display: flex;
  flex-flow: row wrap;
  gap: 1.5rem;
  justify-content: center;
  transition: 0.3s ease-out;
}
#theme .page div.list-of-cards > div {
  position: relative;
  border-radius: 1rem;
  overflow: clip;
  color: var(--color-onSurface);
  width: min(23rem, 100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: var(--color-surface);
  transition: 0.2s;
}
#theme .page div.list-of-cards > div:active:not(.readonly):not(.card:has(.action-group:active)) {
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.1);
  transform: scale(0.99);
}
#theme .page div.list-of-cards > div h3 {
  padding-bottom: 0.5rem;
}
#theme .page div.list-of-cards > div .card-image {
  aspect-ratio: 4/3;
  width: 23rem;
  overflow: clip;
  position: relative;
  z-index: 0;
}
#theme .page div.list-of-cards > div .card-image img, #theme .page div.list-of-cards > div .card-image div {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}
#theme .page div.list-of-cards > div .card-image div {
  background-color: var(--color-info);
  display: flex;
}
#theme .page div.list-of-cards > div .card-image div svg {
  margin: auto;
  width: 3rem;
  height: 3rem;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.1));
  stroke: var(--color-onInfo);
}
#theme .page div.list-of-cards > div .card-image + .card-content {
  position: relative;
  z-index: 1;
  transform: translateY(-1.5rem);
}
#theme .page div.list-of-cards > div .card-content {
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 1rem;
  background-color: var(--color-surface);
}
#theme .page div.list-of-cards > div .card-content h2 {
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
}
#theme .page div.list-of-cards > div .card-content h2.short {
  width: calc(100% - 5rem);
}
#theme .page div.list-of-cards > div .card-content ul li {
  display: grid;
  grid-template-columns: 1fr 5rem;
  height: inherit;
  width: inherit;
  gap: 1rem;
  padding: 0.4rem 0;
}
#theme .page div.list-of-cards > div .card-content ul li + li {
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
}
#theme .page div.list-of-cards > div .card-content ul li div {
  display: flex;
  align-items: center;
}
#theme .page div.list-of-cards > div .card-content ul li div p {
  height: fit-content;
  width: fit-content;
  display: inline-block;
}
#theme .page div.list-of-cards > div .card-content ul li div p + p {
  margin-left: 0.4rem;
}
#theme .page div.list-of-cards > div .card-content ul li span {
  height: 100%;
  width: 100%;
  display: flex;
  display: none;
}
#theme .page div.list-of-cards > div .card-content ul li span svg {
  stroke: var(--color-onSurface);
  opacity: 0.6;
  height: 1.5rem;
  margin: auto;
}
#theme .page div.list-of-cards > div .card-content #shopping-bag-recipe-quantity label {
  display: none;
}
#theme .page div.list-of-cards > div .card-content #shopping-bag-recipe-quantity h3 {
  display: inline-block;
}
#theme .page div.list-of-cards > div .card-content #shopping-bag-recipe-quantity input[type=number] {
  all: unset;
  text-align: center;
  height: fit-content;
  width: fit-content;
  margin: 0 0.5rem;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  appearance: none;
  -moz-appearance: textfield;
  display: inline-block;
}
#theme .page div.list-of-cards > div .card-content #shopping-bag-recipe-quantity input[type=number]::-webkit-inner-spin-button, #theme .page div.list-of-cards > div .card-content #shopping-bag-recipe-quantity input[type=number]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
#theme .page div.list-of-cards > div:has(.card-extra-info) .card-content {
  margin-bottom: 2rem;
}
#theme .page div.list-of-cards > div .card-extra-info {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
  height: 1rem;
  margin: 1rem;
  position: absolute;
  bottom: 0;
}
#theme .page div.list-of-cards > div .card-extra-info .card-extra-info-segment {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  opacity: 0.6;
}
#theme .page div.list-of-cards > div .card-extra-info .card-extra-info-segment svg {
  stroke: var(--color-onSurface);
  height: 1rem;
  width: 1rem;
  padding-left: 0.5rem;
}
#theme .page div.list-of-cards > div .card-extra-actions {
  position: absolute;
  z-index: 1;
  top: 1rem;
  right: 1rem;
  width: fit-content;
  height: fit-content;
  display: flex;
  gap: 0.5rem;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 2.8rem;
  padding: 0.5rem;
}
#theme .page div.list-of-cards > div .card-extra-actions .card-extra-action {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  background-color: var(--color-surface);
  display: flex;
  transition: 0.2s;
}
#theme .page div.list-of-cards > div .card-extra-actions .card-extra-action:active {
  transform: scale(0.98);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
#theme .page div.list-of-cards > div .card-extra-actions .card-extra-action:active.card-extra-action-delete {
  background-color: var(--color-danger);
}
#theme .page div.list-of-cards > div .card-extra-actions .card-extra-action:active.card-extra-action-delete svg {
  stroke: var(--color-surface);
}
#theme .page div.list-of-cards > div .card-extra-actions .card-extra-action svg {
  width: 60%;
  margin: auto;
  stroke: var(--color-onSurface);
  transition: 0.2s;
}
#theme .page div.list-of-cards.list-of-cards-single-row > div {
  width: 100%;
}
#theme .page div.list-of-cards.list-of-cards-single-row #theme-picker div.card-content {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}
#theme .page div.list-of-cards.list-of-cards-single-row #theme-picker div.card-content h2 {
  display: block;
  width: 100%;
  padding: 0;
}
#theme .page div.list-of-cards.list-of-cards-single-row #theme-picker div.card-content div {
  --preview-background: var(--color-background);
  --preview-surface: var(--color-surface);
  --preview-main: var(--color-primary);
  width: 2.8rem;
  height: 2.8rem;
  background-color: var(--color-surface);
  color: var(--color-onSurface);
  font-size: 1.1rem;
  border-radius: 2.8rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 0.2s;
  display: flex;
  width: 4rem;
  height: 4rem;
  background-color: var(--preview-background);
  border: 3px solid var(--preview-background);
  transition: 0.2s;
  position: relative;
  overflow: clip;
}
#theme .page div.list-of-cards.list-of-cards-single-row #theme-picker div.card-content div:active {
  transform: scale(0.98);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
#theme .page div.list-of-cards.list-of-cards-single-row #theme-picker div.card-content div svg {
  stroke: var(--color-onSurface);
  height: 60%;
  margin: auto;
}
#theme .page div.list-of-cards.list-of-cards-single-row #theme-picker div.card-content div::after {
  content: "";
  position: absolute;
  background-color: var(--preview-surface);
  width: 2.8rem;
  height: 2.8rem;
  left: 0;
  top: 0;
  border-radius: 0 0 2.8rem 0;
}
#theme .page div.list-of-cards.list-of-cards-single-row #theme-picker div.card-content div::before {
  content: "";
  position: absolute;
  background-color: var(--preview-main);
  width: 2rem;
  height: 1.6rem;
  right: 0;
  top: 0;
}
#theme .page div.list-of-cards.list-of-cards-single-row #theme-picker div.card-content div.active {
  border: 3px solid var(--color-primary);
}
#theme .page .action-group {
  all: unset;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 0.5rem;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2.8rem;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  top: 1rem;
  transform: scale(1);
}
#theme .page .action-group[data-location=left] {
  left: 1rem;
}
#theme .page .action-group[data-location=right] {
  right: 1rem;
}
#theme .page .action-group.vertical {
  flex-direction: column;
}
#theme .page .action-group.surface {
  background-color: transparent;
}
#theme .page .action-group.surface > .action-group-button {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}
#theme .page .action-group.surface > .action-group-button:active {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}
#theme .page .action-group.surface > .action-group-button.action-group-button-delete svg {
  stroke: var(--color-danger);
}
#theme .page .action-group.surface > .action-group-button.action-group-button-delete:active {
  background-color: var(--color-danger);
}
#theme .page .action-group.surface > .action-group-button.action-group-button-delete:active svg {
  stroke: var(--color-surface);
}
#theme .page .action-group > .action-group-button {
  all: unset;
  cursor: pointer;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  background-color: var(--color-surface);
  display: flex;
  transition: 0.2s;
}
#theme .page .action-group > .action-group-button svg {
  width: 60%;
  margin: auto;
  stroke: var(--color-onSurface);
  fill: transparent;
  transition: 0.2s;
}
#theme .page .action-group > .action-group-button:active {
  transform: scale(0.98);
}
#theme .page .action-group > .action-group-button.action-group-button-hidden {
  display: none;
}
#theme .page .action-group > .action-group-button.action-group-button-delete:active {
  background-color: var(--color-danger);
}
#theme .page .action-group > .action-group-button.action-group-button-delete:active svg {
  stroke: var(--color-surface);
}
#theme .page #image-form {
  width: min(100%, 35rem);
  aspect-ratio: 4/3;
  position: relative;
  margin: auto;
  overflow: clip;
}
#theme .page #image-form .hidden {
  display: none;
}
#theme .page #image-form img, #theme .page #image-form video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}
#theme .page #image-form > div {
  position: absolute;
}
#theme .page #image-form #image-form-preview, #theme .page #image-form #image-form-preview-default {
  z-index: -1;
  height: 100%;
  width: 100%;
}
#theme .page #image-form #image-form-preview div, #theme .page #image-form #image-form-preview-default div {
  background-color: var(--color-info);
  width: 100%;
  height: 100%;
  display: flex;
}
#theme .page #image-form #image-form-preview div svg, #theme .page #image-form #image-form-preview-default div svg {
  margin: auto;
  width: 3rem;
  height: 3rem;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.1));
  stroke: var(--color-onInfo);
}
#theme .page #image-form #image-form-camera {
  z-index: 0;
  height: 100%;
  width: 100%;
  transition: 0.2s;
}
#theme .page #image-form #image-form-camera.hidden button {
  transform: translate(-50%, 100%);
}
#theme .page #image-form #image-form-camera button {
  all: unset;
  position: absolute;
}
#theme .page #image-form #image-form-camera button#image-camera-cancel {
  left: 1rem;
  top: 1rem;
}
#theme .page #image-form #image-form-camera button#image-camera-capture {
  left: 50%;
  bottom: 2rem;
  transform: translate(-50%);
  background-color: transparent;
  transition: 0.2s;
  display: flex;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  border: solid 6px var(--color-primary);
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.1));
}
#theme .page #image-form #image-form-camera button#image-camera-capture:active {
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.1));
  background-color: var(--color-surface);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}
#theme .page #image-form #image-form-actions {
  z-index: 10;
  left: 50%;
  top: calc(50% - 1rem);
  transform: translate(-50%, calc(-50% + 0.5rem));
}
#theme .page #image-form #image-form-actions.preview {
  left: 1rem;
  top: 1rem;
  transform: translate(0, 0);
}
#theme .page #image-form #image-form-actions.preview label, #theme .page #image-form #image-form-actions.preview button {
  width: 2.8rem;
  height: 2.8rem;
}
#theme .page #image-form #image-form-actions:not(.preview) {
  transition: 0.2s;
}
#theme .page #image-form #image-form-actions.nopermission {
  opacity: 0.3 !important;
  pointer-events: none !important;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1), inset 0 0 0 rgba(0, 0, 0, 0.1);
}
#theme .page #image-form #image-form-actions input[type=file] {
  display: none;
}
#theme .page #image-form #image-form-actions label, #theme .page #image-form #image-form-actions button {
  width: 3.5rem;
  height: 3.5rem;
}
#theme .page #image-form #image-form-actions label#image-camera-toggle, #theme .page #image-form #image-form-actions button#image-camera-toggle {
  display: none;
}
#theme .page .growing-text-field {
  all: unset;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: clip;
  color: var(--color-onSurface);
}
#theme .page .growing-text-field:read-only {
  caret-color: transparent;
}
#theme .page .success {
  background-color: var(--color-success);
  color: var(--color-onSuccess);
}
#theme .page .success h2, #theme .page .success h3 {
  color: var(--color-onSuccess);
}
#theme .page .success-text {
  color: var(--color-success);
}
#theme .page .warning {
  background-color: var(--color-warning);
  color: var(--color-onWarning);
}
#theme .page .warning h2, #theme .page .warning h3 {
  color: var(--color-onWarning);
}
#theme .page .warning-text {
  color: var(--color-warning);
}
#theme .page .danger {
  background-color: var(--color-danger);
  color: var(--color-onDanger);
}
#theme .page .danger h2, #theme .page .danger h3 {
  color: var(--color-onDanger);
}
#theme .page .danger-text {
  color: var(--color-danger);
}
#theme .page .extra-info {
  background-color: var(--color-info);
  color: var(--color-onInfo);
}
#theme .page .extra-info h2, #theme .page .extra-info h3 {
  color: var(--color-onInfo);
}

