$title: 2.4rem;
$subtitle: 1.8rem;
$body: 1rem;
$lesser: 0.9rem;

$title-line-height: 1.2;
$subtitle-line-height: 1.4;
$body-line-height: 1.5;

$bold: bold;

$font-family: 'Work Sans', sans-serif;

$title-letter-spacing: calc($title * -0.02);
$subtitle-letter-spacing: calc($subtitle * -0.02);
$body-letter-spacing: normal;