@import "../global-values/constants.scss";
@import "../global-values/theme.scss";

@mixin button() {
    $size: 2.8rem;

    width: $size;
    height: $size;

    background-color: var(--color-surface);
    color: var(--color-onSurface);
    font-size: 1.1rem;

    border-radius: $size;
    box-shadow: $shadow;

    transition: $transition-speed;

    display: flex;

    &:active {
        transform: scale(0.98);
        box-shadow: $shadow-active;
    }

    svg {
        stroke: var(--color-onSurface);
        height: 60%;
        margin: auto;
    }
}

@mixin growingTextField() {
    .growing-text-field {
        all: unset;

        white-space: pre-wrap;
        word-wrap: break-word;

        overflow: clip;

        color: var(--color-onSurface);

        &:read-only {
            caret-color: transparent;
        }
    }
}