@use './interactable.scss';

@import '../global-values/theme.scss';
@import '../global-values/constants.scss';

@mixin style() {
    #theme-picker div.card-content {
        display: flex;
        flex-flow: row wrap;
        gap: $padding;

        h2 {
            display: block;
            width: 100%;
            padding: 0;
        }

        div {
            --preview-background: var(--color-background);
            --preview-surface: var(--color-surface);
            --preview-main: var(--color-primary);

            $size: 4rem;

            @include interactable.button();

            width: $size;
            height: $size;

            background-color: var(--preview-background);
            border: 3px solid var(--preview-background);
            
            transition: $transition-speed;

            position: relative;

            overflow: clip;

            &::after {
                content: '';
                position: absolute;
                background-color: var(--preview-surface);
                width: calc($size * 0.7);
                height: calc($size * 0.7);
                left: 0;
                top: 0;

                border-radius: 0 0 calc($size * 0.7) 0;
            }

            &::before {
                content: '';
                position: absolute;
                background-color: var(--preview-main);
                width: calc($size * 0.5);
                height: calc($size * 0.4);
                right: 0;
                top: 0;
            }

            &.active {
                border: 3px solid var(--color-primary);
            }
        }
    }
}