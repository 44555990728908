@import "../global-values/constants.scss";
@import "../global-values/theme.scss";
@import "../global-values/font.scss";

@mixin style() {
    .modal-backdrop {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;

        z-index: 999;
        background-color: $shadow-color;
        
        pointer-events: none;
        display: none;
        backdrop-filter: blur(3px);

        &.modal-opening {
            animation-name: fadeIn;

            .modal {
                animation-name: flyIn;
            }
        }

        &.modal-closing {
            animation-name: fadeOut;

            &.continue {
                .modal {
                    animation-name: flyOut-Continue;
                }
            }

            &.cancel {
                .modal {
                    animation-name: flyOut-Cancel;
                }
            }
        }

        &.modal-closed {
            opacity: 0; backdrop-filter: blur(0);
            
            .modal {
                transform: translateY(-50%);
            }
        }

        &.modal-open {
            pointer-events: all;
        }

        &:not(.modal-closed) {
            display: flex;
        }

        @keyframes flyIn {
            from {transform: translateY(-10%); box-shadow: 0 0 0px $shadow-color;}
            to {transform: translateY(0); box-shadow: 0 0 10px $shadow-color;}
        }

        @keyframes fadeIn {
            from {opacity: 0; backdrop-filter: blur(0);}
            to {opacity: 1; backdrop-filter: blur(3px);}
        }

        @keyframes flyOut-Continue {
            from {transform: translateX(0); box-shadow: 0 0 10px $shadow-color;}
            to {transform: translateX(-20%); box-shadow: 0 0 0 $shadow-color;}
        }

        @keyframes flyOut-Cancel {
            from {transform: translateX(0); box-shadow: 0 0 10px $shadow-color;}
            to {transform: translateX(20%); box-shadow: 0 0 0 $shadow-color;}
        }

        @keyframes fadeOut {
            from {opacity: 1; backdrop-filter: blur(3px);}
            to {opacity: 0; backdrop-filter: blur(0);}
        }

        .modal {
            background-color: var(--color-info);
            color: var(--color-onInfo);
            border-radius: $rounding;

            height: fit-content;
            width: calc(min(80%, 30rem) - $padding * 2);
            padding: $padding;

            display: flex;
            flex-flow: column nowrap;
            align-items: center;

            margin: auto;

            box-shadow: $shadow;

            text-align: center;

            p {
                font-size: 1.2rem;
                margin-bottom: $padding;
            }

            .modal-actions {
                display: flex;
                flex-flow: row nowrap;
                gap: $padding;

                button {
                    all: unset;
                    padding: 0 $sub-padding;
                    border-radius: 2.8rem;
    
                    width: 5rem;
                    height: 2.8rem;
    
                    &:active {
                        transform: scale(0.98);
                    }
    
                    &.modal-continue { 
                        background-color: var(--color-primary);
                        color: var(--color-surface);
                    }
    
                    &.modal-cancel { 
                        background-color: var(--color-danger);
                        color: var(--color-onDanger);
                    }
                }
            }

            .modal-container {
                padding: $padding;
                background-color: var(--color-surface);
                margin-bottom: $padding;

                border-radius: $rounding;

                box-shadow: inset $shadow-active;

                #shopping-bag-modal-quantity {
                    display: flex;
                    flex-flow: row-reverse wrap;
                    align-items: center;
                    justify-items: center;
    
                    height: fit-content;
                    width: 5rem;
    
                    transition: $transition-speed;
                    
                    color: var(--color-onSurface);
                    border-bottom: solid var(--color-onSurface) 1px;
                    border-radius: $rounding-field;
                    padding: 0 $rounding-field $sub-padding $rounding-field;
    
                    &:has(input:focus) {
                        svg {
                            stroke: var(--color-primary);
                        }
        
                        border-bottom: solid var(--color-primary) 1px;
                    }
    
                    label {
                        display: block;
                        height: 1.2rem;
                        width: 1.2rem;
    
                        svg {
                            width: 100%;
                            stroke: var(--color-onSurface);
                            transition: 0.2s;
                        }
                    }
    
                    input[type="number"] {
                        all: unset;
                        text-align: center;
                        height: fit-content;
                        width: calc(100% - 1.2rem);
                        appearance: none;
                        -moz-appearance: textfield;

                        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button  {
                            display: none;
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}