$light-shades: #edeae3;
$light-accent: #4d565d;
$main-brand: #74978e;
$dark-accent: #795d5b;
$dark-shades: #2a2c35;
$success: #58a863;
$warning: #d5982b;
$danger: #f44336;

:root {
    --surface: #edeae3;
    --main-brand: #74978e;
    --accent: #4d565d;
    --background: #2a2c35;
    --success: #58a863;
    --warning: #d5982b;
    --danger: #f44336;
    background-color: var(--dark-shades);
}

html, body {
    background-color: var(--dark-shades);
}

#theme {
    --color-primary: var(--main-brand);

    --color-success: var(--success);
    --color-onSuccess: var(--surface);

    --color-warning: var(--warning);
    --color-onWarning: var(--surface);
    
    --color-danger: var(--danger);
    --color-onDanger: var(--surface);

    --color-info: var(--accent);
    --color-onInfo: var(--surface);

    --color-background: var(--background);
    --color-onBackground: var(--surface);

    --color-surface: var(--surface);
    --color-onSurface: var(--background);
}
