$rounding: 1rem;
$rounding-field: 5px;

$section-padding: 2rem;
$padding: calc($section-padding * 0.5);
$sub-padding: calc($padding * 0.5);

$shadow-color: rgba(0,0,0,0.1);
$shadow-position: 0 0 10px;
$shadow-position-active: 0 0 5px;
$shadow: $shadow-position $shadow-color;
$shadow-active: $shadow-position-active $shadow-color;

$transition-speed: 0.2s;
