@import "../global-values/constants.scss";
@import "../global-values/theme.scss";

@mixin style() {
    #image-form {
        $button-size: 3.5rem;

        width: min(100%, 35rem);
        aspect-ratio: 4 / 3;

        position: relative;

        margin: auto;

        overflow: clip;

        .hidden {
            display: none;
        }

        img, video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            overflow: hidden;
        }

        > div {
            position: absolute;
        }

        #image-form-preview, #image-form-preview-default {
            z-index: -1;

            height: 100%;
            width: 100%;

            div {
                background-color: var(--color-info);
                width: 100%;
                height: 100%;
                display: flex;

                svg {
                    margin: auto;
                    width: 3rem;
                    height: 3rem;

                    filter: drop-shadow($shadow);

                    stroke: var(--color-onInfo);
                }
            }
        }

        #image-form-camera {
            z-index: 0;

            height: 100%;
            width: 100%;

            transition: 0.2s;

            &.hidden {
                button{
                    transform: translate(-50%, 100%);
                }
            }

            button {
                all: unset;
                position: absolute;

                &#image-camera-cancel {
                    left: $padding;
                    top: $padding;
                }
                
                &#image-camera-capture {
                    left: 50%;
                    bottom: calc($padding * 2);
    
                    transform: translate(-50%);
    
                    background-color: transparent;
    
                    transition: 0.2s;
    
                    display: flex;
        
                    height: $button-size;
                    width: $button-size;
        
                    border-radius: 50%;
                    border: solid 6px var(--color-primary);
    
                    box-shadow: inset 0 0 0 $shadow-color;
    
                    filter: drop-shadow(0 0 10px $shadow-color);
    
                    &:active {
                        filter: drop-shadow(0 0 5px $shadow-color);
                        background-color: var(--color-surface);
                        box-shadow: inset 0 0 10px $shadow-color;
                    }
                }
            }
        }
        
        #image-form-actions {
            z-index: 10;

            left: 50%;
            top: calc(50% - $padding);

            transform: translate(-50%, calc(-50% + $padding * 0.5));

            &.preview {
                left: $padding;
                top: $padding;

                transform: translate(0, 0);

                label, button {
                    width: 2.8rem;
                    height: 2.8rem;
                }
            }

            &:not(.preview) {
                transition: $transition-speed;
            }

            &.nopermission {
                opacity: 0.3 !important;
                pointer-events: none !important;
                box-shadow: 0 0 0 $shadow-color, inset 0 0 0 $shadow-color;
            }

            input[type="file"] {
                display: none;
            }
    
            label, button {
                width: $button-size;
                height: $button-size;

                &#image-camera-toggle {
                    display: none;
                }
            }
        }
    }
}