@import "../global-values/constants.scss";
@import "../global-values/theme.scss";

@mixin style() {
    .action-group {
        all: unset;

        $button-size: 2.8rem;

        width: fit-content;
        height: fit-content;

        display: flex;

        flex-wrap: nowrap;
        flex-direction: row;

        gap: $sub-padding;

        background-color: $shadow-color;
        border-radius: $button-size;

        padding: $sub-padding;

        position: absolute;
        z-index: 1;

        top: $padding;

        transform: scale(1);

        &[data-location="left"]{
            left: $padding;
        }

        &[data-location="right"]{
            right: $padding;
        }

        &.vertical {
            flex-direction: column;
        }

        &.surface {
            background-color: transparent;

            & > .action-group-button {
                box-shadow: inset $shadow-active;

                &:active {
                    box-shadow: inset $shadow;
                }

                &.action-group-button-delete {
                    svg {
                        stroke: var(--color-danger);
                    }

                    &:active {
                        background-color: var(--color-danger);
    
                        svg {
                            stroke: var(--color-surface);
                        }
                    }
                }
            }
        }

        & > .action-group-button {
            all: unset;
            cursor: pointer;

            width: $button-size;
            height: $button-size;

            border-radius: 50%;

            background-color: var(--color-surface);

            display: flex;
            
            transition: $transition-speed;

            svg {
                width: 60%;

                margin: auto;

                stroke: var(--color-onSurface);
                fill: transparent;

                transition: $transition-speed;
            }

            &:active {
                transform: scale(0.98);
            }

            &.action-group-button-hidden {
                display: none;
            }

            &.action-group-button-delete {
                &:active {
                    background-color: var(--color-danger);

                    svg {
                        stroke: var(--color-surface);
                    }
                }
            }
        }
    }
}